@font-face {
  font-family: "Archivo";
  src: url(../public/assets/fonts/Archivo/Archivo-Medium.ttf);
}

@font-face {
  font-family: "Urbanist";
  src: url(../public/assets/fonts/Urbanist/Urbanist-ExtraBold.ttf);
}

.App {
  background: #f9fcff;

  * {
    font-family: "Archivo";
  }
  a {
    text-decoration: none;
  }
  .container {
    margin: 0 auto;
    padding: 25px 0px;
    max-width: 1016px;
    @media (max-width: 1226px) {
      margin: 5px 100px;
    }
    @media (max-width: 600px) {
      margin: 25px 20px;
    }
  }
  .title-text {
    font-family: "Urbanist";
    font-style: normal;
    font-weight: 900;
    font-size: 32px;
    line-height: 120%;

    color: #004ba3;
  }

  .subtitle-text {
    font-family: "Archivo";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 128%;

    letter-spacing: 0.02em;

    color: #1a1a1a;
  }
}
