.Joblist {
    .no-jobs{
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        font-size: 20px;
        font-weight: 600;
        padding-bottom: 20px;
    }
    .main{
        padding-top: 70px;
        @media (max-width: 1100px){
            padding-top: 150px;
        }
    }
    .background{
        position: absolute;
        min-height: 307px;
        min-width: 100%;
        left: 0px;

        background: #E6F2FF;
    }
    .container{
        position: relative;
        .badges{
            display:flex;
            justify-content: center;
            @media (max-width: 800px){
                flex-direction: column;
                align-items: center;
            }
            .Badge{
                max-width: 33%;
                min-width: 33%;
                @media (max-width: 800px){
                    max-width: 80%;
                    min-width: 80%;
                    margin-bottom: 20px;
                }
            }
            .Badge:first-child{
                margin-right: 20px;
                @media (max-width: 800px){
                    margin-right: 0;
                }
            }
        }
        .section-1{
            .wrapper{
                display:flex;
                padding-bottom: 60px;
                @media (max-width: 1100px){
                    padding-top: 20px;
                    flex-direction: column;
                    align-items: center;
                    padding-bottom: 40px;
                }
                .left{
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    @media (max-width: 1100px){
                        align-items: center
                    }
                }
                .title-text{
                    margin-top: -80px;
                    margin-right: 175px;
                    display: flex;
                    justify-content: center;
                    align-content: center;
                    align-items: center;
                    @media (max-width: 1100px){
                        margin-right: 0;
                    }

                }
                .image{
                    position:relative;
                    max-width: 50%;
                    .block{
                        max-width: 500px;
                        @media (max-width: 1100px){
                            max-width: 100%;
                        }
                    }
                                    
                    .striped_rect{
                        z-index: 10;
                        position: absolute;
                        left: -25px;
                        bottom: -25px;
                        width: 205px;
                        height: 40px;
                        background: repeating-linear-gradient(125deg, transparent, transparent 7px, rgba(63, 150, 249, 0.501) 0px, rgba(63, 150, 249, 0.501) 10px);
                    }
                }
                
            }
            
        }
        .joblisting{
            padding-top: 42px;
            .striped_rect{
                width: 190px;
                height: 28px;
                background: repeating-linear-gradient(125deg, transparent, transparent 7px, rgba(63, 150, 249, 0.501) 0px, rgba(63, 150, 249, 0.501) 10px);
            }
            
            .title {
                font-weight: bolder;
                font-size: 32px;
                padding-bottom: 1rem;
                font-weight: 900;
                line-height: 120%;
                font-family: "Urbanist";
                color: #004ba3;
            }
            .joblisting-buttons{
                @media (max-width: 800px){
                    padding-top: 30px;
                }
                padding-bottom: 30px;

                font-weight: 400;
                font-size: 14px;
                line-height: 15px;
                text-transform: capitalize;

                color: #3F95F9;
                display: flex;
                justify-content: center;
                .ant-radio-button-wrapper{
                    height: 100%;
                    padding-top: 5px;
                    padding-bottom: 5px;
                    border: none!important;
                }
                .ant-radio-button-wrapper-checked:focus-within{
                    border: none;
                }
                .ant-radio-button-wrapper::before{
                    background: none;
                }
                .ant-radio-button-wrapper-checked{
                    background: #E6F2FF;
                    border-color: none;
    
                }
            }
            .jobs{
                padding-top:25px;
                justify-content: center;
                display: flex;
                @media(max-width:1200px){
                  flex-direction: column;
                }
          
              }
          
              .jobs > .job-row{
                display: flex;
                justify-content: center;
                flex-wrap: wrap;
                flex-direction: row;
                @media(max-width:1200px){
                    margin-right: 0px;
                    display: flex;
                    justify-content: space-between;
                  flex-direction: column;
                }
              }

              .jobs >.job-row:last-child{
                margin-right: 0px;
              }
              .JobCard {
                min-width: 450px;
                margin: 10px;
                display: flex; 
                flex-basis: calc(50% - 20px);  
                justify-content: center;
                flex-direction: column;
                @media(max-width:1200px){
                  max-width: 100%;
                  min-width: 0px;
                }
            }
            .joblisting-pagination{
                padding-top: 25px;
                display: flex;
                justify-content: center;
            }
        }
    }
}