.JobCard {
  background: #ffffff;
  border: 1px solid #eaf3ff;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  .wrapper {
    padding: 16px;
    min-width: 100%;
    min-height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .top {
      display: flex;
      justify-content: space-between;
      padding-bottom: 28px;
      .specialization {
        padding: 5px 9px;
        border-radius: 4px;
        font-weight: 700;
        font-size: 14px;
      }
      .location {
        padding: 5px 9px;
        border-radius: 4px;
        font-size: 14px;
        background: #e6f2ff;
        color: #344054;
        .location-icon {
          width: 10.67px;
          margin-right: 8px;
        }
      }
    }
    .job-title {
      font-weight: 700;
      font-size: 16px;
      line-height: 17px;
      padding-bottom: 20px;

      color: #004ba3;
    }
    .tags {
      display: flex;
      flex-wrap: wrap;
      .tag {
        font-weight: 500;
        font-size: 12px;
        line-height: 13px;
        text-transform: capitalize;
        color: #3f95f9;
        padding: 3px 8px;
        border: 1px solid #3f95f9;
        border-radius: 4px;
        margin-right: 4px;
        margin-bottom: 4px;
      }
    }
    .buttons {
      margin-top: 25px;
      display: flex;
      justify-content: space-between;

      .l {
        background: #3f95f9;
        color: #ffffff;
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
      .r {
        color: #3f95f9;
        font-weight: 500;
      }
      a {
        width: 50%;
        text-align: center;
        padding: 10px 20px;
        font-weight: 400;
        font-size: 14px;
        border-radius: 4px;
        cursor: pointer;
      }
    }
    .buttons > div {
      width: 50%;
      text-align: center;
      padding: 10px 20px;
      font-weight: 400;
      font-size: 14px;
      border-radius: 4px;
      cursor: pointer;
    }

    .buttons > div:hover {
      filter: brightness(110%);
    }
  }
}
