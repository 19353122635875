.HomeJob {
    .arrow_ver{
        display: none;
    }
    .striped_rect{
        display: none;
    }
    .job-box{
        margin-top:-24px;
        padding: 24px;
        background: white;
        border: 1px solid #EAF3FF;
        border-radius: 4px;
        .job-box__title{
            font-family: 'Urbanist';
            font-style: normal;
            font-weight: 900;
            font-size: 32px;
            line-height: 38px;
            text-align: center;
            @media (max-width: 600px){
                text-align: center;
                font-size: 26px;
            }

            color: #08356B;
        }
        .job-box__text{
            font-family: 'Archivo';
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 20px;
            /* identical to box height, or 125% */

            @media (max-width: 600px){
                text-align: center;
            }
            color: #191919;

        }
        .job-box__details{
            padding-right: 50%;
            @media (max-width: 900px){
                padding-right: 15%;
            }
            font-family: 'Inter';
            font-style: normal;
            font-weight: 300;
            font-size: 16px;
            line-height: 20px;
            /* identical to box height, or 125% */
            
            
            color: #344054;

            padding-top:10px;
            display: flex;
            justify-content: space-between;

            @media (max-width: 600px){
                text-align: center;
                flex-direction: column;
                padding-right: 0;
                .job-box__details__item{
                    margin-bottom: 10px;
                }
            }
            .job-box__details__item__value{
                                
                color: #3F95F9;
            }
        }
    }
    .job-details{
        padding-top: 40px;
        margin:auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 70%;
        
        .job-details__title{
            font-family: 'Archivo';
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 20px;
            padding-bottom: 15px;
            /* identical to box height, or 125% */


            color: #191919;
        }
        .detail-text{
            

            font-family: 'Archivo';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 150%;
            /* or 24px */
            
            letter-spacing: 0.02em;
            
            color: #191919;}
        .bottom-box{
            p{
                margin-bottom: 8px;
                
            }

            p::before{
                content: "•";
                color: #3F95F9;
                padding-right: 10px;
            }
            
            display: flex;
            justify-content: space-between; 
            gap:20px;
            @media (max-width: 900px){
                flex-direction: column;
            }
            div{
                flex:1;
            }
        }
    }
    .more_questions{
        margin-top: 40px;
    background: #E6F2FF;
    padding-bottom: 20px;
        .title-box{
          padding-top: 32px;
          padding-bottom: 20px;
        }
        .tel_icon{
          display: none;
        }
        .tel_container{
          margin:auto;
        }
    text-align: center;
    justify-content: center;
    }
    
    .clause {
        text-align: center;
        padding: 8px;
        margin-left: 4px;
        font-weight: 400;
        font-size: 12px;
        letter-spacing: 0.02em;
        color: #8f9ba9;
      }
      .form-box{
        display: flex;
        width: 100%;
        
        @media (max-width: 1100px){
            width: 100%;
            display: flex;
            margin-left: 120px;
            justify-content: center;
            @media (max-width: 600px){
                .form_wrapper .ant-form{
                    max-width: 100%;

                }
            }
        }
        
        @media (max-width: 600px){
            margin: 0;
        }
        .title_box{
            text-align:left;
            padding-bottom: 20px;
        }

        .ant-form-item{
            margin-left:20px;
            max-width: 60%;
            @media (max-width: 600px){
                    max-width: 100%;
            }
        }
      }
      .form-img{
        display: flex;
        position: relative;
        @media (max-width: 1100px){
            flex-direction: column;
        }
        .Carousel{ 
            
            top: 250px;
            right: 530px;
            @media (max-width: 1100px){
                display: none;
            }
        }
      }
      
}