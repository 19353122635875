.Testimonial {
    font-weight: 500;
    font-size: 18px;
    text-align: center;
    .wrapper{
        max-width: 350px;
        padding-bottom: 20px;
        @media (max-width: 1200px){
            padding-bottom: 50px;
        }
        .text{
            min-height: 180px;
            max-height: 180px;
            @media (max-width: 1200px){
                min-height: 100%;
                margin-bottom: -20px;
                font-size: 16px;
            }
        }
        .image{
            padding-top:28px;
            padding-bottom: 8px;
            img{
                border-radius: 100%;
                max-width: 64px;
            }
        }
        .stars{
            padding-top: 2px;
            color: #FEC84B;
        }
    }
}