.Footer {
    background: #EAF0F7;
    .copyright{
        
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        padding-bottom: 24px;

        color: #8F9BA9;

    }
    .wrapper{
        padding: 40px 0 24px;
        display: flex;
        justify-content: space-between;
        @media (max-width: 600px){
            flex-direction: column; 
        }
        
    }
        .collumn{
            @media (max-width: 600px){
                padding-bottom: 30px;
            }
            .socials{
                @media (max-width: 600px){
                    
                    display:inline-block;
                    padding: 0;
                    padding-bottom: 30px;
                    svg{
                    margin-left: 10px;
                    margin-bottom:-15px;
                    }
                }
                padding: 10px 2px;
                svg{
                    width: 24px;
                    height: 24px;
                    border-radius: 100%;
                }
                .facebook{
                    color: #4267B2
                }
                
            }
            .title{
                padding-bottom: 16px;
                @media (max-width: 600px){
                    padding-bottom: 10px;
                }
                
                font-weight: 700;
                font-size: 16px;
                line-height: 128%;
                letter-spacing: 0.02em;

                color: #004BA3;

                
            }
            .text{
                
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 150%;
                letter-spacing: 0.02em;
                a{
                    color: #1A1A1A;
                }
                .cyan{
                    
                    color:  #3F95F9
                }
        
        }
    }
}