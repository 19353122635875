.Carousel{
    position:relative;
    height: 100%;
    .striped_rect{
        position:absolute;
        left:-30px;
        bottom: 8px;
        width: 240px;
        height: 42px;
        background: 
        /* On "top" */
        repeating-linear-gradient(125deg, transparent, transparent 9px, rgba(63, 150, 249, 0.501) 0px, rgba(63, 150, 249, 0.501) 12px);
        @media (max-width: 600px){
            width: 165px;
            height: 30px;
            left: -10px;
            bottom: 72.5px;
        }
    }
    .arrow_ver{
        position: absolute;
        bottom:0px;
        left:215px;
        @media (max-width: 600px){
            bottom:68px;
            left: 160px;
            img{
                width: 78%;
            }
        }
    }
    .arrow_hor{
        position: absolute;
        top:0px;
        left:270px;
        @media (max-width: 600px){
            margin-left:-10px;
            left: 185px;
            img{
                width: 108%;
            }
        }
    }


    .one{
        z-index: 1;
    }

    .two{
        z-index: -1;
    }

    .picture-l{
        position: absolute;
        img{
            max-width: 260px !important;
            max-height: 300px!important;
            min-width: 260px;
            min-height: 300px;
            @media (max-width: 600px){
                margin-left:-10px;
                max-width: 183px !important;
                max-height: 200px!important;
                min-width: 183px;
                min-height: 200px;
            }
        }
    }
    .picture-r{
        position: absolute;
        left:232px;
        top:30px;
        @media (max-width: 600px){
            margin-left:-10px;
            left: 180px;
            top: 20px;
        }
        img{
            max-width: 300px!important;
            max-height: 327px!important;
            min-width: 300px;
            min-height: 327px;
            @media (max-width: 600px){
                max-width: 195px !important;
                max-height: 220px!important;
                min-width: 195px;
                min-height: 220px;
            }
        }
    }
}