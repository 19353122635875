.Form {
  .ant-form-item-label
    > label.ant-form-item-required:not(
      .ant-form-item-required-mark-optional
    )::before {
    display: none;
  }
  .form-error {
    color: #ff4d4f;
    font-size: 14px;
    padding-bottom: 10px;
    text-align: center;
  }
  @media (max-width: 600px) {
    .title {
      font-size: 24px !important;
      text-align: center;
    }
    .subtitle {
      font-size: 16px;
      text-align: center;
    }
  }
  @media (max-width: 600px) {
    .title-text {
      font-size: 24px !important;
      text-align: center;
    }
    .subtitle-text {
      font-size: 16px;
      text-align: center;
    }
  }
  .title_box {
    text-align: center;
    padding: 50px 0px;
    .title {
      font-weight: bolder;
      font-size: 32px;
      padding-bottom: 16px;
    }
    .subtitle {
      font-weight: 400;
      font-size: 16px;
      letter-spacing: 0.02em;
      color: #1a1a1a;

      padding-bottom: 1rem;
    }
  }

  .form_wrapper {
    display: flex;
    justify-content: center;
    .ant-form {
      min-width: 560px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      @media (max-width: 600px) {
        min-width: 100%;
      }
      input {
        font-size: 16px;
      }
      .ant-form-item {
        .ant-form-item-label {
          max-width: 100%;
          font-size: 14px;
          padding-bottom: 3px;
        }

        input {
          padding: 6px 12px;
        }

        .ant-col-14 {
          max-width: 100%;
        }
      }
      .ant-upload {
        .ant-btn {
          border: 2px dashed #bfbfbf62;
          width: 100%;
          text-align: left;
          height: 37.5px;
        }
        .ant-btn:hover {
          border: 2px dashed #40a9ff;
        }
        .upload_text {
          color: #bfbfbf;
        }
      }
      .ant-upload-select-picture {
        width: 100%;
      }
      .ant-form-item-control-input-content > button {
        width: 100%;
      }
      .submit {
        min-width: 100% !important;
        border: none;

        display: flex;
        justify-content: center;
        vertical-align: middle;
        align-items: center;

        font-weight: 200;
        font-size: 14px;

        background: #3f95f9;
        color: white;
        padding: 11.5px 17.5px;
        max-height: 40px;
        min-height: 40px;
        border-radius: 4px;

        cursor: pointer;
        transition: all 0.2s ease-in-out;
      }
      .submit:hover {
        filter: brightness(110%);
      }
    }
  }
}
