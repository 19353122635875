.Home {
  .jss7,
  .jss23,
  .jss4,
  .jss13 {
    margin-top: 20px;
    @media (max-width: 1200px) {
      margin-top: 0px;
    }
    display: flex;
    justify-content: center;
  }

  .jss8,
  .jss5 {
    margin: 0 2px;

    width: 10px;
    height: 4px;

    background: #aebccc;
    border-radius: 10px;
  }
  //.jss9,
  .jss6 {
    background: #3f95f9;
  }
  @media (max-width: 600px) {
    .title {
      font-size: 24px !important;
      text-align: center;
    }
    .subtitle {
      font-size: 16px;
      text-align: center;
    }
  }
  @media (max-width: 600px) {
    .title-text {
      font-size: 24px !important;
      text-align: center;
    }
    .subtitle-text {
      font-size: 16px;
      text-align: center;
    }
  }
  .clause {
    text-align: center;
    padding: 8px;
    margin-left: 4px;
    font-weight: 400;
    font-size: 12px;
    letter-spacing: 0.02em;
    color: #8f9ba9;
  }

  .background {
    position: absolute;
    z-index: -10;
    width: 100%;
    margin: 0 auto;
    height: 200px;
    background: rgb(255, 255, 255);
    background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 1) 0%,
      rgba(230, 242, 255, 1) 80%
    );
  }

  .title {
    font-family: "Urbanist";
    color: #004ba3;
  }

  .container {
    padding-top: 36px;
  }

  .section-1 {
    display: grid;
    grid-template-columns: 45% 30% 25%;
    vertical-align: middle;
    padding-bottom: 5px;
    grid-auto-rows: 1fr;

    @media (max-width: 1200px) {
      display: flex;
      align-items: center;
      flex-direction: column;
    }

    .left {
      width: fit-content;
      padding: 2.7rem 0;
      margin-bottom: 3rem;
      @media (max-width: 1200px) {
        padding: 0;
        margin-bottom: 2rem;
      }

      .title {
        font-weight: bolder;
        font-size: 32px;
        padding-bottom: 1rem;
        font-weight: 900;
        line-height: 120%;
      }
      .subtitle {
        font-weight: 400;
        font-size: 16px;
        letter-spacing: 0.02em;
        color: #1a1a1a;

        padding-bottom: 2rem;
      }
      .flex_buttons {
        display: flex;
      }
    }
    .right {
      grid-column-start: 2;
      grid-column-end: span 2;
      height: 366px;
      width: 558px;
      @media (max-width: 600px) {
        height: 310px;
        width: 350px;
      }
    }
  }

  .badges {
    display: flex;
    justify-content: space-between;
    padding-bottom: 40px;
    @media (max-width: 600px) {
      flex-direction: column;
      align-items: center;
    }
    .col2 {
      display: flex;
      justify-content: space-between;
      min-width: 50%;
    }
    .badge {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin: 8px 10px;
      background: #d6e9ff8a;
      width: 240px;
      height: 90px;
      border-radius: 4px;
      @media (max-width: 600px) {
        width: 180px;
      }

      .badge-icon {
        padding-bottom: 5px;
        img {
          width: 23px;
        }
      }
      .badge-text {
        font-weight: 500;
        font-size: 14px;

        line-height: 120%;
        display: flex;
        align-items: center;
        text-align: center;

        color: #004ba3;
      }
    }
  }
  .section-2 {
    @media (max-width: 1200px) {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    @media (max-width: 600px) {
      .title-box {
        align-self: start;
        .title {
          text-align: left;
        }
      }
    }
    .striped_rect {
      width: 205px;
      height: 28px;
      @media (max-width: 600px) {
        height: 28px;
        margin-left: -25px;
      }
      background: repeating-linear-gradient(
        125deg,
        transparent,
        transparent 7px,
        rgba(63, 150, 249, 0.501) 0px,
        rgba(63, 150, 249, 0.501) 10px
      );
    }
    .title {
      font-weight: bolder;
      font-size: 32px;
      padding-bottom: 1rem;
      font-weight: 900;
      line-height: 120%;
    }
    .jobs {
      padding-top: 25px;
      justify-content: center;
      gap: 20px;
      display: flex;
      @media (max-width: 1200px) {
        flex-direction: column;
      }
    }

    .jobs > .JobCard {
      max-width: 32%;
      @media (max-width: 1200px) {
        max-width: 100%;
        margin-bottom: 20px;
      }
    }
  }
  .testimonials {
    padding-top: 70px;
    overflow-x: hidden;
    @media (max-width: 1200px) {
      padding-top: 40px;
    }

    .jss1 {
      min-width: 112%;
      margin-left: -6%;
    }
    .title-box {
      padding-bottom: 70px;
      text-align: center;
      justify-content: center;
      @media (max-width: 1200px) {
        padding-bottom: 40px;
      }
    }
    .testimonials-wrapper {
      margin: 0 6%;
      display: flex;
      justify-content: space-between;
      @media (max-width: 1200px) {
        flex-direction: column;
        align-items: center;
        margin: 0;
      }

      .Testimonial {
        padding-right: 80px;
        @media (max-width: 1200px) {
          padding-right: 0;
        }
      }

      .Testimonial:last-child {
        padding-right: 0;
      }
    }
  }
  .FAQ {
    padding-top: 40px;
    text-align: center;
    justify-content: center;
    .Faq {
      padding-bottom: 32px;
    }
    .title-box {
      padding-bottom: 42px;
      .title-text {
        padding-bottom: 14px;
      }
    }
  }
  .more_questions {
    background: #e6f2ff;
    padding-bottom: 20px;
    .title-box {
      padding-top: 32px;
      padding-bottom: 20px;
    }
    .tel_icon {
      display: none;
    }
    .tel_container {
      margin: auto;
    }
  }
}
