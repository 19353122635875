.NotFound {
    .not-found{
        display: flex;
        flex-direction: column;
        align-items: center;
        height: calc(100vh - 400px);
        justify-content: center;
        .not-found__title{
            font-size: 40px;
            font-weight: 600;
            color: #000000;
        }
        .not-found__text{
            font-size: 16px;
            font-weight: 300;
            color: #000000;
            padding-top: 20px;
        }
    }
}