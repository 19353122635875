.Contact {
    
    .container{
        .title-box{
            
            text-align: center;
            padding-bottom: 40px;
            @media (max-width: 600px){
                padding-bottom: 20px;
                
                font-size: 24px!important;
                text-align: center;
            }
        }
        .badges{
            display:flex;
            justify-content: space-between;
            padding-bottom: 40px;
            @media (max-width: 600px){
                flex-direction: column;
                align-items: center;
            }
            .Badge{
                max-width: 33%;
                min-width: 32%;
                @media (max-width: 600px){
                    max-width: 100%;
                    min-width: 100%;
                    margin-bottom: 20px;
                }
            }
        }
    }

}