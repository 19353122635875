.About {
    @media (max-width: 600px) {
        .title{
          font-size: 24px!important;
          text-align: center;
        }
        .subtitle{
          font-size: 16px;
          text-align: center;
        }
      }
      @media (max-width: 600px) {
        .title-text{
          padding-top: 20px;
          font-size: 24px!important;
          text-align: center;
        }
        .subtitle-text{
          font-size: 16px;
          text-align: center;
        }
      }
    .background{
        position: absolute;
        min-height: 307px;
        min-width: 100%;
        left: 0px;

        background: #E6F2FF;
    }
    .container{
        position: relative;
        .badges{
            display:flex;
            justify-content: center;
            @media (max-width: 800px){
                flex-direction: column;
                align-items: center;
            }
            .Badge{
                @media (max-width: 800px){
                    max-width: 80%;
                    min-width: 80%;
                    margin-bottom: 20px;
                    
                .title {
                    font-size: 16px!important;
                    text-align: center;
                }
                }
                max-width: 33%;
                min-width: 33%;
            }
            .Badge:first-child{
                margin-right: 20px;
                @media (max-width: 800px){
                    margin-right: 0;
                }
            }
        }
        .section-1{
            padding-bottom: 40px;
            @media (max-width: 1100px){
                padding-top: 80px;
            }
            .wrapper{
                display:flex;
                padding-bottom: 60px;
                @media (max-width: 1100px){
                    flex-direction: column;
                    align-items: center;
                    padding-bottom: 40px;
                }
                .title-text{
                    margin-top: -80px;
                    margin-right: 50px;
                    @media (max-width: 1100px){
                        margin-right: 0;
                        text-align: center;
                    }
                    display: flex;
                    justify-content: center;
                    align-content: center;
                    align-items: center;

                }
                .image{
                    position:relative;
                    max-width: 50%;
                    .block{
                        max-width: 500px;
                        @media (max-width: 1100px){
                            max-width: 100%;
                        }
                    }
                                    
                    .striped_rect{
                        z-index: 10;
                        position: absolute;
                        left: -25px;
                        bottom: -25px;
                        width: 205px;
                        height: 40px;
                        background: repeating-linear-gradient(125deg, transparent, transparent 7px, rgba(63, 150, 249, 0.501) 0px, rgba(63, 150, 249, 0.501) 10px);
                    }
                }
            }
            .mark{
                width: 366px;
                background: #E6F2FF;
                border-radius: 4px;
                margin: auto;
                @media (max-width: 1100px){
                    width: 100%;
                }
                .mark-wrapper{
                    display: flex;
                    padding: 16px 44px;
                    .mark-text{
                        
                        font-weight: 500;
                        font-size: 16px;
                        line-height: 150%;
                        /* or 24px */

                        display: flex;
                        align-items: center;
                        letter-spacing: 0.02em;

                        color: #004BA3;
                    }
                    .mark-icon{
                        padding-right:25px ;
                        display: flex;
                        align-items: center;
                        img{
                            max-width: 35px;
                        }
                    }
                }
            }
        }
        .section-2{
            .item{
                display: flex;
                justify-content: space-between;
                padding-bottom: 64px;
                @media (max-width: 1100px){
                    flex-direction: column;
                    align-items: center;
                    padding-bottom: 40px;
                }
                .left{
                    font-weight: 400;
                    font-size: 16px;
                    letter-spacing: 0.02em;
                    max-width: 45%;
                    @media (max-width: 1100px){
                        max-width: 100%;
                    }
                    .item-icon{
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        margin-bottom: 16px;
                        width: 48px;
                        height: 48px;

                        background: #E6F2FF;
                        border-radius: 4px;
                        img{
                            max-width: 26px;
                        }
                        
                    }
                    .item-title{
                        max-width: 80%;
                        font-weight: 900;
                        font-size: 24px;
                        line-height: 120%;
                        
                        color: #004BA3;
    
                        padding-bottom: 16px;
                    }
                    .item-text{
                        p{
                            margin-bottom: 4px;
                        }
                        span{
                            line-height: 80%;
                            color: #3F95F9;
                            font-weight: bolder;
                            font-size: 20px;
                            padding-right: 3px;
                        }
                    }
                }
                .right{
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    img{
                        width: 500px;
                        height: 270px;
                        @media (max-width: 1100px){
                            width: 100%;
                            height: 100%;
                            padding: 10px 0px;
                        }
                    }
                }
            }
        }
    }
    
    .main{
        padding-top: 70px;
    }
}