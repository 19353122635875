.Badge {
    background: rgba(214, 233, 255, 0.54);
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    min-height: 100%;
    .wrapper{
        min-width: 100%;
        padding: 14px;
        .image{
            img{
                max-height: 24px;
            }
        }
        .title{
            
            font-weight: 700;
            font-size: 16px;
            line-height: 17px;
            padding-bottom: 10px;
            padding-top: 14px;
        }
        .text-wrapper{
            display: flex;
            justify-content: center;
            padding-bottom: 8px;
            
            .text{
                max-width: 70%;
                font-weight: 400;
                font-size: 14px;
                line-height: 128%;
                /* identical to box height, or 18px */
                text-align: center;
                letter-spacing: 0.02em;

                color: #1A1A1A;
            }

        }
        .cyan{               
            color:  #3F95F9;
            font-weight: 500;
            font-size: 16px;
            line-height: 120%;
        }
    }
}