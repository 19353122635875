.navbar-wrapper {
  @media (max-width: 900px) {
    padding-bottom: 75px;
  }
  .container {
    @media (max-width: 900px) {
      padding: 0;
      margin: 0;
      position: absolute;
      display: flex;
      justify-content: center;
      width: 100%;
      .mobile-menu {
        @media (max-width: 900px) {
          position: absolute;
          width: 100%;
        }
      }

      .Navbar {
        width: 100%;
        padding: 25px 11%;
        position: fixed;
        background: #f9fcff;
        z-index: 50;
      }
    }
    @media (max-width: 600px) {
      margin: 0;
      .Navbar {
        width: 100%;
        padding: 25px 10px;
      }
    }
  }
}

.Navbar {
  display: flex;
  justify-content: space-between;
  text-align: center;

  .desktop {
    @media (max-width: 900px) {
      display: none !important;
    }
  }
  .mobile {
    @media (min-width: 901px) {
      display: none !important;
    }
  }

  .closed {
    opacity: 0;
    display: none !important;
    z-index: -1;
    & {
      z-index: -1;
    }
  }

  .open {
    opacity: 1;
    display: inherit;
  }
  .mobile-menu {
    transition: all 0.15s ease-out;
    box-shadow: 0 3px 6px -2px grey;
    width: 100vw;
    padding: 0;
    left: 0;
    top: 100px;
    background: #f9fcff;
    position: absolute;
    display: flex;
    flex-direction: column;
    .links {
      padding: 10px 0;
      background: #f9fcff;
      .item {
        padding: 10px 0;
        border-bottom: 1px solid #aebccc;
        color: #004ba3;

        font-weight: 700;
        font-size: 16px;
        cursor: pointer;
        transition: all 0.2s ease-in-out;
        &:hover {
          filter: brightness(110%);
        }
      }
    }
    .buttons {
      padding: 10px 0;
      background: #f9fcff;
      .lang_button {
        color: #004ba3;
        font-weight: 600;
        font-size: 14px;

        background-color: white;
        padding: 12px 10px;
        margin: 0px 10px;
        height: 45px;
        width: 50px;

        border: 1px outset #aebccc;
        border-radius: 4px;

        cursor: pointer;
        transition: all 0.3s ease-in-out;
      }

      .lang_button:hover {
        background-color: #004ba3;
        color: white;
        border-color: #004ba3;
      }
      .tel_wrapper {
        display: flex;
        justify-content: center;
        padding-top: 15px;
        padding-bottom: 15px;

        .tel_container {
          width: 40%;
          display: flex;
          justify-content: center;
          vertical-align: middle;
          align-items: center;

          font-weight: 200;
          font-size: 14px;

          background: #1ebd53;
          color: white;
          padding: 11.5px 17.5px;
          margin-left: 15px;
          max-height: 40px;
          border-radius: 4px;

          cursor: pointer;
          transition: all 0.2s ease-in-out;
          .tel_text {
            padding-left: 10px;
            white-space: nowrap;
          }
          .tel_icon {
            max-width: 20px;
          }
        }
        .tel_container:hover {
          filter: brightness(110%);
        }
      }
    }
  }

  .mobile {
    color: #004ba3;
    font-size: 26px;
    padding: 12px;
    background: #ecf5ff;
  }

  .left {
    display: flex;
    justify-content: center;
    vertical-align: middle;
    align-items: center;
    margin-left: 5px;
    margin-top: -10px;
    .logo {
      min-width: 120px;
      max-width: 120px;
    }
  }
  .center {
    display: flex;
    justify-content: center;
    vertical-align: middle;
    align-items: center;
    .item {
      padding: 0 22.4px;
      vertical-align: middle;
      color: #004ba3;
      font-weight: 400;
      font-size: 16px;
    }
  }
  .right {
    display: flex;
    justify-content: center;
    vertical-align: middle;
    align-items: center;

    .lang_button {
      color: #004ba3;
      font-weight: 600;
      font-size: 14px;

      background-color: white;
      padding: 12px 10px;
      margin: 0px 10px;
      height: 45px;
      width: 50px;

      border: 1px outset #aebccc;
      border-radius: 4px;

      cursor: pointer;
      transition: all 0.3s ease-in-out;
    }

    .lang_button:hover {
      background-color: #004ba3;
      color: white;
      border-color: #004ba3;
    }

    .tel_container {
      display: flex;
      justify-content: center;
      vertical-align: middle;
      align-items: center;

      font-weight: 200;
      font-size: 14px;

      background: #1ebd53;
      color: white;
      padding: 11.5px 17.5px;
      margin-left: 15px;
      max-height: 40px;
      border-radius: 4px;

      cursor: pointer;
      transition: all 0.2s ease-in-out;
      .tel_text {
        padding-left: 10px;
        white-space: nowrap;
      }
      .tel_icon {
        max-width: 20px;
      }
    }
    .tel_container:hover {
      filter: brightness(110%);
    }
  }
}
