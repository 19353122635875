.Button {
    .tel_container {
        display: flex;
        justify-content: center;
        vertical-align: middle;
        align-items: center;
    
        font-weight: 200;
        font-size: 14px;
    
        color: white;
        padding: 11.5px 17.5px;
        min-height: 40px;
        min-width: 170px;
        max-width: 170px;
        border-radius: 4px;
    
        cursor: pointer;
        transition: all 0.2s ease-in-out;
        .tel_text {
          padding-left: 10px;
          white-space: nowrap;
        }
        .tel_icon {
          max-width: 20px;
        }
      }
    
      .tel_container:hover {
        filter: brightness(110%);
      }
}