.CookiePolicy {
    .container{
        padding-bottom: 40px;
        .title{
            font-weight: 700;
            font-size: 32px;
            line-height: 120%;
            color: #004BA3;
            padding: 0 0 20px 0;
        }
        .text{
            font-weight: 400;
            font-size: 16px;
            line-height: 128%;
            letter-spacing: 0.02em;
            color: #1A1A1A;
        }
        .ul{
            list-style: none;
            padding-left: 0;
            li{
                font-weight: 400;
                font-size: 16px;
                line-height: 128%;
                letter-spacing: 0.02em;
                color: #1A1A1A;
            }
        }
    }
}