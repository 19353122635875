.Faq {
    display: flex;
    justify-content: center;
    .ant-collapse{
        border: none;
        min-width: 950px;
        display: block;
        text-align: left;
        background-color: white;
        @media (max-width: 600px){
            min-width: 100%;
        }
        .ant-collapse-header{
            border: none;
            font-weight: 700;
            font-size: 18px;
            line-height: 28px;
            @media (max-width: 600px){
                font-size: 16px;
                line-height: 24px;
            }
            /* identical to box height, or 156% */
            color: #004BA3;
            .ant-collapse-header-text{
                padding: 10px 10px 0 5px;
            }

        }
        .ant-collapse-item-active{
            transition: all 0.2s ease-in-out;
            background: #E6F2FF;
            div{
                border: none;
            }
        }
        .ant-collapse-item{
            border-bottom: none;
            border-radius: 4px;
            .ant-collapse-expand-icon{
                
            order: 1;

            }
            
            div{border: none;}
        }
        .ant-collapse-content-active{
            transition: all 0.3s ease-in-out;
            background: #E6F2FF;
        }
        .ant-collapse-content{
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            @media (max-width: 600px){
                font-size: 14px;
                line-height: 22px;
            }
            /* identical to box height, or 150% */

            letter-spacing: 0.02em;

            color: #004BA3;

            .ant-collapse-content-box{
                padding: 0px 22.5px 10px;
            }

        }
    }
}