.Breadcrumbs {
    .container{
        .wrapper{
            display: flex;
            padding: 0px 2px;
            .home{
                color:#191919;
                font-size: 18px;
            }
            .item{
            display: flex;
            .arrow{
                padding: 0 14px;
                display: flex;
                height: 100%;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                color:#8F9BA9;
                font-size: 12px;
            }
            .text_badge{
                background: #E6F2FF;
                border-radius: 4px;
                
                .text{
                    padding: 4px 8px;
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 20px;
                    /* identical to box height, or 143% */
                    
                    
                    color: #3F95F9; 
                }
            }
            }
        }
    }
}